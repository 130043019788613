<template>
  <div>
    <TabTitle class="mb-4" icon="fa-file-contract">
      <template>LOA Management</template>
      <template #sub-title>Upload an LOA and link it to an account.</template>
    </TabTitle>

    <div class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <div v-if="error" class="alert alert-danger">{{ error }}</div>

        <div class="row">
          <div class="col-md-4">
            <Spinner v-if="assetsLoading.list" size="md" />
            <FormGroup
              v-else
              id="gridfetch-asset"
              v-model="assetId"
              class="mb-0"
              type="select2"
              :config="{ allowClear: true, allowSearch: true }"
              :options="assetOptions"
              placeholder="Select an Asset..."
              @input="onSelectAsset"
            />

            <div v-if="assetId" class="mt-3">
              <Spinner v-if="accountsLoading.list" size="md" />
              <FormGroup
                v-else
                id="gridfetch-account"
                v-model="accountId"
                class="mb-0"
                type="select2"
                :config="{ allowClear: true, allowSearch: true }"
                :options="accountOptions"
                placeholder="Select an Account..."
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="accountId" class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <h4 class="mb-3">LOA Upload</h4>

        <p v-if="selectedAccountMpan" class="mb-3"><strong>Selected MPAN:</strong> {{ selectedAccountMpan }}</p>

        <FormGroup id="gridfetch-loa-date" v-model="expiryDate" label="Expiry Date" class="mb-0" type="datePicker" placeholder="Select a date" />

        <FileUpload
          v-model="file"
          :progress="gridfetch.loaUploadProgress"
          :error="error"
          :loading="uploading"
          :file-types="['pdf', 'docx']"
          class="bg-light mb-3 mt-3"
        />

        <p v-if="gridfetch.loaUrl" class="mb-3"><strong>URL (For debug):</strong> {{ gridfetch.loaUrl }}</p>

        <button class="btn btn-primary" :class="{ disabled: !file.file }" :disabled="!file.file" @click="onClickUpload">Upload to Gridfetch</button>
      </div>
    </div>

    <div class="block block-rounded block-fx-pop">
      <div class="block-content block-content-full">
        <DataTable
          :items="loaAccounts.data"
          :total="loaAccounts.total"
          :loading="loaAccountsLoading"
          :columns="loaAccountsColumns"
          :page-size="20"
          :fetch-items="listAccounts"
          default-sort-key="uploadedAt"
          :default-sort-direction="-1"
          :other-params="loaAccountsParams"
        >
          <template v-slot:gridfetchLoa.expiryDate="slotProps">
            {{ slotProps.data.gridfetchLoa.expiryDate | date('Do MMM YYYY HH:mm:ss') }}
          </template>
          <template v-slot:uploadedBy="slotProps">
            {{ slotProps.data.gridfetchLoa.uploadedBy | user(users) }}
          </template>
          <template v-slot:uploadedAt="slotProps">
            {{ slotProps.data.gridfetchLoa.uploadedAt | date('Do MMM YYYY HH:mm:ss') }}
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import DataTable from '@/components/DataTable';
import FileUpload from '@/components/FileUpload';
import FormGroup from '@/components/FormGroup';
import Spinner from '@/components/Spinner';
import TabTitle from '@/components/base/TabTitle';

export default {
  name: 'AdminGridfetch',
  components: {
    DataTable,
    FileUpload,
    FormGroup,
    Spinner,
    TabTitle
  },
  data() {
    return {
      accountId: null,
      assetId: null,
      file: {},
      progress: null,
      error: null,
      uploading: false,
      uploaded: false,
      assetAccounts: [],
      loaAccounts: {
        data: [],
        total: 0
      },
      loaAccountsLoading: true,
      loaAccountsParams: { 'gridfetchLoa.uploaded': 'true' },
      expiryDate: null
    };
  },
  computed: {
    ...mapGetters({
      loading: 'admin/loading',
      storeError: 'admin/error',
      accountsLoading: 'account/loadingAction',
      assetsLoading: 'asset/loadingAction',
      accounts: 'account/accounts',
      assets: 'asset/assets',
      gridfetch: 'admin/gridfetch',
      users: 'company/users'
    }),
    assetOptions() {
      const assets = [...this.assets];

      assets.sort((a, b) => a.siteName.localeCompare(b.siteName));

      return assets.map(a => ({
        label: a.siteName,
        value: a._id
      }));
    },
    accountOptions() {
      const accounts = [...this.assetAccounts];

      accounts.sort((a, b) => a.type.localeCompare(b.type));
      accounts.sort((a, b) => a.status.localeCompare(b.status));

      return accounts.map(a => ({
        label: `${a.status === 'active' ? '' : `[${a.status.toUpperCase()}] `}${a.meterPointNumber} - ${a.type}`,
        value: a._id
      }));
    },
    selectedAccountMpan() {
      if (!this.accountId) {
        return null;
      }

      const selectedAccount = this.accounts.find(a => a._id === this.accountId);

      if (!selectedAccount) {
        return null;
      }

      const mpan =
        selectedAccount.type === 'electricity' && selectedAccount.meterPointNumber.length > 13
          ? selectedAccount.meterPointNumber.slice(-13)
          : selectedAccount.meterPointNumber;

      return mpan;
    },
    loaAccountsColumns() {
      return [
        {
          name: 'File Name',
          code: 'gridfetchLoa.name'
        },
        {
          name: 'Expiry Date',
          code: 'gridfetchLoa.expiryDate'
        },
        {
          name: 'MPAN',
          code: 'meterPointNumber'
        },
        {
          name: 'Uploaded By',
          code: 'uploadedBy'
        },
        {
          name: 'Uploaded At',
          code: 'uploadedAt'
        },
        {
          name: 'Error',
          code: 'gridfetchLoa.error'
        }
      ];
    }
  },
  async mounted() {
    this.listUsers({ id: this.$auth.companyId });
    this.listAssets();
    this.listLoaAccounts({ data: { params: this.loaAccountsParams } });
  },
  beforeDestroy() {
    this.clearStore();
  },
  methods: {
    ...mapActions({
      getGridfetchLoaUrl: 'admin/getGridfetchLoaUrl',
      uploadGridfetchLoa: 'admin/uploadGridfetchLoa',
      listAccounts: 'account/list',
      listAssets: 'asset/list',
      listUsers: 'company/listUsers'
    }),
    ...mapMutations({
      clearStore: 'admin/CLEAR_STORE'
    }),
    async listLoaAccounts(...params) {
      this.loaAccountsLoading = true;
      this.loaAccounts = await this.listAccounts(...params);
      this.loaAccountsLoading = false;
    },
    async onSelectAsset() {
      const { data } = await this.listAccounts({ data: { params: { assetId: this.assetId } } });
      this.assetAccounts = data;
    },
    async onClickUpload() {
      this.uploading = true;

      console.log(this.file.file);

      this.file.file.set('mpan', this.selectedAccountMpan);
      this.file.file.set('accountId', this.accountId);
      this.file.file.set('expiryDate', this.expiryDate);

      const response = await this.uploadGridfetchLoa({ data: this.file.file });

      console.log(response);

      if (response?.status === 'uploaded') {
        this.$toasted.success('LOA uploaded successfully');
      } else {
        this.$toasted.error('LOA upload failed');
        const error = response?.error || this.storeError;
        this.error = 'LOA upload failed: ' + error;
      }

      this.listLoaAccounts({ data: { params: this.loaAccountsParams } });

      this.uploaded = true;
      this.uploading = false;
    }
  }
};
</script>
